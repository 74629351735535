li.answerItem {
    border: 0.2rem solid #ccc;
    border-radius: 0.4rem;
    margin-bottom: 0.2rem;
}

li.answerItemSelected {
    border: 0.2rem solid darkcyan;
    border-radius: 0.4rem;
    background: lightcyan;
    margin-bottom: 0.2rem;
}

#questionExecutionPage {
    max-width: 1200px;
    margin: auto;
}
